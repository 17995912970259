<template>
  <div class="text-center">
    <v-dialog
      v-model="showInformMeDialog"
      max-width="450"
      transition="dialog-top-transition"
      persistent
    >
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="inform-me">
            <v-row>
              <v-col cols="12">
                <div class="justify-end">
                  <v-btn
                    icon
                    variant="plain"
                    class="close-dialog-icon"
                    @click="closeInformMe()"
                  >
                    <v-icon text>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-title>
                  <div class="title text-center mt-6">
                    <h3>أخبرني عند التوفر</h3>
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" v-if="props.isCustomerLogIn">
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <v-card-text
                      class="content-body text-center text-caption font-weight-bold mt-12"
                    >
                      <p class="mt-12">تم تفعيل إشعار أخبرني عند التوفر</p>
                      <p class="mx-4 mt-4">
                        سيتم إرسال إشعار إلى بريدك الإلكتروني عند توفر هذه
                        المادة. يمكنك أيضًا العثور على إشعار توفر المادة في قسم
                        الإشعارات على الموقع
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="action-btn text-center ma-8 mt-0">
                      <v-spacer></v-spacer>
                      <!-- <v-card-actions class="text-center"> -->
                      <v-btn
                        @click="okInformMeAboutMaterial()"
                        rounded="xl"
                        :loading="loading"
                        size="large"
                        color="#87599d"
                        class="inform-me-btn"
                        block
                        dark
                      >
                        حسناً
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!--  Not Login -->

              <v-col cols="12" v-else>
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <v-card-text
                      class="content-body text-center text-caption font-weight-bold align-items-top mt-0"
                    >
                      <p class="mt-0">
                        يرجى تسجيل الدخول لتفعيل إشعار التوفر وسنقوم بإعلامك فور
                        توفر المنتج .
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="action-btn text-center mx-5 mt-0">
                      <!-- :loading="loading" -->
                      <v-btn
                        @click="signIn()"
                        rounded="xl"
                        size="large"
                        color="#87599d"
                        class="inform-me-btn"
                        block
                        dark
                      >
                        تسجيل الدخول
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  justify="center"
                  :class="{ 'mx-4': smAndDown, 'mx-2': !smAndDown }"
                >
                  <v-col cols="5">
                    <div class="horizontal-line" />
                  </v-col>
                  <v-col cols="2" md="1" xs="2" class="text-center">
                    <span class="or"> أو </span>
                  </v-col>
                  <v-col cols="5">
                    <div class="horizontal-line" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <v-card-text
                      class="content-body text-center text-caption font-weight-bold align-items-top"
                    >
                      <p class="">
                        أدخل بريدك الإلكتروني ورقم الواتساب وسنقوم بإعلامك فور
                        توفر المنتج
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mx-4">
                  <v-form ref="formRef" class="modal-form" lazy-validation>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.email"
                        class="input-email"
                        placeholder="البريد الإلكتروني"
                        type="email"
                        :rules="emailRules"
                        validate-on-blur
                        @blur="() => formRef.resetValidation()"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row justify="center align-center" class="phon-filed">
                        <v-col cols="12" class="d-flex align-center">
                          <v-text-field
                            v-model="form.phone"
                            placeholder="رقم الواتساب"
                            class="phone-num"
                            hide-spin-buttons
                            :min-length="7"
                            :rules="phoneNumberRules"
                            validate-on-blur
                            @blur="() => formRef.resetValidation()"
                          >
                          </v-text-field>

                          <v-autocomplete
                            v-if="selectedCountry"
                            class="left-hint country-key"
                            v-model="form.country_id"
                            :items="countries"
                            item-title="phone_code"
                            item-value="id"
                            dense
                            filled
                            outlined
                            solo
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-row>
                      <v-col cols="12">
                        <div class="text-center mb-8">
                          <v-btn
                            @click="confirmSingUp"
                            rounded="xl"
                            size="large"
                            color="#87599d"
                            block
                            dark
                          >
                            تأكيد
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { useAuthStore } from "~/stores/AuthStore";
import { useHomeStore } from "~/stores/HomeStore";
import { useCartStore } from "~/stores/CartStore";

const { smAndDown } = useDisplay();

const authStore = useAuthStore();
const homeStore = useHomeStore();
const cartStore = useCartStore();

const emit = defineEmits([
  "onLogIn",
  "closeInformMeDialog",
  "onSuccessMessage",
]);
const props = defineProps({
  openInformMeDialog: {
    type: Boolean,
    default: false,
  },
  isCustomerLogIn: {
    type: Boolean,
    default: false,
  },
  productId: {
    type: String,
    default: "",
  },
  productType: {
    type: String,
    default: "",
  },
});
const formRef = ref(null);
const form = ref({
  email: "",
  phone: "",
  country_id: "",
});
const loading = ref(false);
const showInformMeDialog = ref(false);
const errors = ref({
  country: { show: false, message: "" },
  phone: { show: false, message: "" },
});

const requiredRules = ref([(v) => !!v || "الحقل مطلوب"]);
const emailRules = ref([
  (v) => !!v || "الحقل مطلوب",
  (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "البريد الإلكتروني  غير صحيح",
]);
const phoneNumberRules = ref([
  (v) => !!v || "هذا الحقل مطلوب",
  (v) => /^\d+$/.test(v) || "يجب ان تكون القيمة ارقام فقط",
  (v) => (v && v.length >= 7) || "رقم الهاتف  يجب أن لا يقل عن 7 رقم",
  (v) => (v && v.length <= 15) || "رقم الهاتف  يجب أن لا يزيد عن 15 رقم",
]);

watch(
  () => props.openInformMeDialog,
  (newVal) => {
    showInformMeDialog.value = newVal;
  },
  { immediate: true },
);

const countries = computed(() => {
  return homeStore.countries ? homeStore.countries.countries : [];
});

const selectedCountry = computed({
  get() {
    if (form.value.country_id == "" && countries.value.length) {
      const saudiArabia = countries.value.find(
        (country) => country.name == "السعودية",
      );
      if (saudiArabia) form.value.country_id = saudiArabia.id;
      return form.value.country_id;
    }
    return form.value.country_id;
  },
  set(newVal) {
    form.value.country_id = newVal;
  },
});

const user = computed(() => authStore.auth.user);

const signIn = () => {
  emit("onLogIn");
};
onMounted(() => {
  setTimeout(async () => {
    if (countries.length > 0) {
      if (!form.value.country_id && countries.value) {
        form.value.country_id = countries.value[0].id;
      }
    }
    //  else await homeStore.fetchCountries({ list: true, select_country: true });
  }, 2000);
});

const confirmSingUp = async () => {
  const check = await formRef.value.validate();
  const formData = new FormData();
  if (check.valid) {
    formData.append("email", form.value.email);
    formData.append("phone", form.value.phone);
    formData.append("country_id", form.value.country_id);
    formData.append("productable_id", props.productId);
    formData.append("productable_type", props.productType);
    try {
      await cartStore
        .informMeAboutProductsAvailabilities(formData)
        .then((response) => {
          showSuccessMessage(response);
        });
    } catch (err) {
      console.log(err);
    }
  }
};
const okInformMeAboutMaterial = async () => {
  loading.value = true;
  const formData = new FormData();

  formData.append("full_name", user.value.full_name);
  formData.append("email", user.value.email);
  formData.append("phone", user.value.phone !== null ? user.value.phone : "");
  formData.append("country_id", user.value.country_id);
  formData.append("productable_id", props.productId);
  formData.append("productable_type", props.productType);
  try {
    await cartStore
      .informMeAboutProductsAvailabilities(formData)
      .then((response) => {
        showSuccessMessage(response);
      });
  } catch (err) {
    console.log(err);
  }
  loading.value = false;
};

const closeInformMe = () => {
  showInformMeDialog.value = false;
  emit("onCloseInformMeDialog", false);
  form.value = { email: "", phone: "", country_id: "" };
};
const showSuccessMessage = (data) => {
  emit("onSuccessMessage", data);
  if (data.success) closeInformMe();
};
</script>

<style lang="scss" scoped>
.inform-me {
  .horizontal-line {
    border: 1px solid rgb(174, 173, 173);
  }
  .modal-form {
    .phon-filed {
      .phone-num {
        width: 70% !important;
        border-radius: 0px !important;
        .v-input .v-input__control .v-field {
          border-radius: 0px !important ;
        }
      }
    }
    .left-hint {
      direction: rtl !important;
    }
  }
  .error-message {
    color: #ff5252;
    font-size: 12px;
    .error {
      font-weight: 500;
    }
  }
}
@media (max-width: 960px) {
  .inform-me {
    .modal-form {
      .phon-filed {
        .phone-num {
          width: 60% !important;
        }
      }
    }
  }
}
</style>
