<template>
  <v-dialog
    persistent
    v-model="state.showDialog"
    :width="xsOnly ? '70%' : '390'"
  >
    <v-card>
      <v-card-title class="d-flex justify-center" />
      <v-card-text class="text-center">
        <p v-if="isNewCustomer">
          <strong class="confirm-text">
            عذراً الطرف الثاني لا يملك حساب في الموقع ،
            <br />
            سنقوم بإرسال الهدية مع دعوة لتسجيل ، ستدرج المادة في حسابه فور
            التسجيل في الموقع
          </strong>
        </p>
        <div v-else>
          <p v-if="subscriptionType === 'new'">
            <strong class="confirm-text">
              أنت تقوم الآن بإهداء اشتراك جديد
              <br />
              وهذه الخطوة غير قابله للتراجع
            </strong>
          </p>
          <p v-else>
            <strong class="confirm-text">
              أنت تقوم الآن بإهداء اشتراك حالي من حسابك إلى حساب المهدى إليه
              <br />
              وهذه الخطوة غير قابله للتراجع
            </strong>
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-row justify="center">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              @click="$emit('confirm')"
              elevation="2"
              class="ml-6 confirm-btn"
            >
              تأكيد
            </v-btn>
            <v-btn
              @click="$emit('dialogClosed')"
              elevation="2"
              class="cancel-btn"
            >
              إلغاء
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useDisplay } from "vuetify";

// Define props
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  isNewCustomer: {
    type: Boolean,
    default: false,
  },
  subscriptionType: {
    type: String,
    default: "new",
  },
});

const { xsOnly } = useDisplay();

// Define reactive state
const state = reactive({
  showDialog: false,
});

// Watch for changes in the 'show' prop
watch(
  () => props.show,
  (newVal) => {
    state.showDialog = newVal;
  },
);
</script>

<style lang="scss" scoped>
.confirm-btn {
  background: #8e6ba1;
  color: #fff;
}
.cancel-btn {
  background: #eeeeee;
}

p {
  font-size: 15px;
  margin-bottom: 0;
}
</style>
