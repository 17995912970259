import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from "~/stores/AuthStore";
import { fa } from 'vuetify/locale';

export const useGiftStore = defineStore("gift", {
  state: () => ({
    giftableData: null,
    loading: false,
  }),

  actions: {
    async updateEmail(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}gifts/${payload.gift_id}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload.form_data,
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        console.error(err);
        this.loading = false;
        return err.response ? err.response.data : err;
      }
    },
    async checkGiftable(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}gift/check-if-giftable`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.giftableData = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
        this.loading = false;
        return err.response ? err.response.data : err;
      }
    },
    async addNewGift(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}gifts`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        console.error(err);
        this.loading = false;
        return err.response ? err.response.data : err;
      }
    },
  },
});
